import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../../components/Layout';

import { downloadFile } from '../../utils/common';

import imgBlockR from '../../assets/images/download-brochure/GirlinBarcelona.jpg';
import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';
import imgDots from '../../assets/images/home-page/Dots.png';

import fileTEFLIberiaBrochure from '../../assets/files/TEFL Iberia brochure web.pdf';

import '../../assets/styles/pages/download-brochure-page.scss';

const EMPTY_FORM_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
};

const DownloadFreeBrochure = () => {
  const [formData, setFormData] = useState(EMPTY_FORM_DATA);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const sourceLabel = 'TEFL Brochure';
    const MailchimpData = {
      FNAME: formData.firstName,
      LNAME: formData.lastName,
      MMERGE4: new Date().toISOString().split('T')[0], // contact date
      MMERGE17: sourceLabel, // Source
    };

    await fetch('/api/download-brochure', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        sourceLabel,
        userFromGoogleAds: window?.userFromGoogleAds,
        userFromFacebookAds: window?.userFromFacebookAds,
      }),
    });
    addToMailchimp(formData.userEmail, MailchimpData);
    downloadFile('brochure-2022.pdf', fileTEFLIberiaBrochure);

    setFormData(EMPTY_FORM_DATA);

    navigate('/tefl-iberia-free-ebook/thank-you');
  };
  return (
    <>
      <Helmet>
        {/* <!-- Meta Pixel Code --> */}
        <meta name="facebook-domain-verification" content="7dgbxitwc2e5jw9goe17a2mhn2aroq" />
        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1389098578493723');
            fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=1389098578493723&ev=PageView&noscript=1"
            />`}
        </noscript>
        {/* <!-- End Meta Pixel Code --> (edited)  */}
      </Helmet>

      <Layout
        title="TEFL Iberia brochure"
        description="Find out how to get started as an online teacher with our step-by-step guide."
      >
        <div className="download-brochure-page">
          <section className="second-section">
            <div className="container">
              <div className="row top">
                <div className="col-12 col-lg-6 col-md-7">
                  <div className="block_l">
                    <h1 className="c-title-42">Download your free ebook!</h1>
                    <p className="c-text-18">
                      Learn about how to get started as an English teacher with our internationally
                      recognized qualification.{' '}
                    </p>
                    <form onSubmit={handleSubmit} className="form">
                      <h1 className="c-title-22 form__title">Get your free ebook now</h1>

                      <label className="form__first-name">
                        <p className="c-text-14 text-under">First Name</p>
                        <input
                          className="input__first-name"
                          type="text"
                          placeholder="John"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                      <label className="form__last-name">
                        <p className="c-text-14 text-under">Last Name</p>
                        <input
                          className="input__last-name"
                          type="text"
                          placeholder="Smith"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          required
                        />
                      </label>

                      <label className="form__first-name email">
                        <p className="c-text-14 text-under">Email</p>
                        <input
                          className="input__email"
                          type="email"
                          placeholder="johnsmith@gmail.com"
                          name="userEmail"
                          value={formData.userEmail}
                          onChange={handleInputChange}
                          required
                        />
                      </label>
                      <a href={fileTEFLIberiaBrochure}>
                        <button type="submit" className="form__btn c-btn c-btn--red">
                          Download my brochure
                        </button>
                      </a>
                    </form>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-md-5">
                  <div className="block_r">
                    <div className="block_r__img">
                      <img src={imgBlockR} alt="" className="block_r__img" />
                    </div>

                    <div className="block__logo block_r__trinity">
                      <img src={imgDots} alt="iconTrinity" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default DownloadFreeBrochure;
